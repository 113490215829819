import './App.css'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import OpdMainWindow from './OpdMainWindow/OpdMainWindow'
import UserContext from './UserContext'
import NotificationContext from './NotificationContext'

const queryClient = new QueryClient()

function App() {
  return (
    <NotificationContext>
      <UserContext>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <OpdMainWindow />
          </BrowserRouter>
        </QueryClientProvider>
      </UserContext>
    </NotificationContext>
  )
}

export default App
