import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Grid,
  FormControl,
  TextField,
  DialogActions,
  Button,
} from '@mui/material'
import { isMobile } from '../Services/Services'
import { useState } from 'react'
import { primaryDark } from '../constants'

export function OpdEditor({
  open,
  edit,
  editItem,
  onSubmit,
  onCancel,
  reqInProgress,
}) {
  const initalVals = {
    name: edit && editItem ? editItem.name : '',
    phoneNo: edit && editItem ? editItem.phoneNo : '',
    regNo: edit && editItem ? editItem.regNo : null,
  }

  const [name, setName] = useState(initalVals.name)
  const [phoneNo, setPhoneNo] = useState(initalVals.phoneNo)
  const [regNo, setRegNo] = useState(initalVals.regNo)

  const isOpdValid = () => {
    if (!name?.trim()?.length) {
      return true
    }

    if (
      regNo === undefined ||
      regNo === null ||
      isNaN(parseInt(regNo)) ||
      parseInt(regNo) < 0
    ) {
      return true
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        // onCancel();
      }}
      fullWidth={true}
      maxWidth={'md'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEscapeKeyDown
      fullScreen={isMobile()}
    >
      <DialogTitle id="alert-dialog-title">
        {edit ? `Edit OPD` : 'New OPD'}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ py: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  label="Name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  label="Phone No"
                  value={phoneNo}
                  onChange={(e) => {
                    setPhoneNo(e.target.value)
                  }}
                ></TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  inputProps={{
                    min: 1,
                    step: 1,
                  }}
                  type="number"
                  label="Registration No"
                  value={regNo}
                  onChange={(e) => {
                    setRegNo(e.target.value)
                  }}
                ></TextField>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel()
          }}
          variant="outlined"
          sx={{
            width: '150px',
            border: `1px solid ${primaryDark}`,
            color: primaryDark,
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={reqInProgress || isOpdValid()}
          variant="contained"
          onClick={() => {
            let data = editItem || {}
            data = {
              ...data,
              name,
              phoneNo,
              regNo: parseInt(regNo),
            }
            onSubmit(data, !edit)
          }}
          sx={{
            width: '150px',
            background: primaryDark,
            ':hover': {
              bgcolor: primaryDark,
            },
          }}
          autoFocus
        >
          {reqInProgress ? 'Loading...' : edit ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
