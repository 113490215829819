import {
  Box,
  AppBar,
  Typography,
  ListItemButton,
  ListItemText,
  Button,
} from '@mui/material'
import { primaryColor, serverUrl } from '../constants'
import { useLocation, useNavigate } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useState, useContext } from 'react'
import { isMobile } from '../Services/Services'
import { Context } from '../UserContext'

export default function OpdAppBar({ children }) {
  const userContext = useContext(Context)

  const navigate = useNavigate()
  const logout = () => {
    localStorage.removeItem('token')
    userContext.setCurrUser(null)
    navigate('/')
  }
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          background: primaryColor,
          transition: 'all .5s ease',
          zIndex: (theme) =>
            isMobile() ? theme.zIndex.drawer : theme.zIndex.drawer + 1,
        }}
      >
        <Box
          sx={{
            padding: isMobile() ? '8px' : '10px 24px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {/* {isMobile() && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(true)}
              edge="start"
              sx={{ mr: 1 }}
            >
              <MenuIcon />
            </IconButton>
          )} */}
          <Box
            sx={{
              background: 'white',
              borderRadius: '50%',
              padding: '7px',
              width: '30px',
              height: '30px',
              cursor: 'pointer',
              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            }}
          >
            <img
              onClick={() => {
                navigate('/')
              }}
              src="../logo.png"
              alt="AMD Dental Clinic"
              style={{
                width: '100%',
              }}
            />
          </Box>
          <Box
            sx={{
              width: '1px',
              height: '45px',
              borderRadius: '9999px',
              background: 'rgba(255, 255, 255, 0.1)',
              margin: '0 25px',
            }}
          ></Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            <Box sx={{ minWidth: '20px' }}>
              {!isMobile() && (
                <Typography
                  variant="h6"
                  sx={{
                    background: 'rgba(0, 0, 0, 0.2)',
                    padding: '5px 25px',
                    borderRadius: '4px',
                  }}
                >
                  OPD App
                </Typography>
              )}
            </Box>
            {userContext.currUser && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  sx={{
                    background: 'rgba(0, 0, 0, 0.2)',
                    color: 'white',
                  }}
                  onClick={logout}
                >
                  Logout
                </Button>
                {serverUrl.includes('localhost') && (
                  <div
                    style={{
                      margin: '5px',
                      width: '10px',
                      height: '10px',
                      background: '#eeff41',
                      borderRadius: '50%',
                    }}
                  ></div>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </AppBar>
    </>
  )
}
