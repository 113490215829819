import { useState } from 'react'
import { Box, Paper, CircularProgress, Grid, Typography } from '@mui/material'
import { serverUrl, primaryColor } from '../constants'
import './Login.css'
import { useLocation } from 'react-router-dom'

function Login() {
  const [loading, setLoading] = useState(false)
  let location = useLocation()
  console.log(location)
  const openGoogleLogin = () => {
    setLoading(true)
    if (location.pathname !== '/login') {
      localStorage.setItem(
        'lastLocation',
        `${location.pathname}${location.search}`
      )
    }
    window.open(`${serverUrl}/auth/google/initiate`, '_self')
  }

  return (
    <Grid container>
      <Grid item md={6} display={{ xs: 'none', md: 'block' }}>
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            alt=""
            src="../login3.png"
            style={{
              height: '50%',
            }}
          />
        </div>
      </Grid>
      <Grid item md={6} xs={12}>
        <div
          style={{
            height: '100vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: primaryColor,
          }}
        >
          <Paper elevation={3} sx={{ width: '80%' }}>
            <Box
              sx={{
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                // background: secondaryColorLight
              }}
            >
              <div
                style={{
                  width: '20%',
                }}
              >
                <img
                  src="../logo.png"
                  alt="home"
                  style={{
                    width: '100%',
                  }}
                />
              </div>
              <Box
                style={{
                  textAlign: 'center',
                  margin: '20px 0',
                }}
              >
                <Typography variant="h5" color="initial">
                  Welcome to AMD Dental Clinic
                </Typography>
                <Box
                  sx={{
                    // border: '1px solid black',
                    p: 1,
                    borderRadius: '12px',
                    background: '#eee',
                  }}
                >
                  <Typography variant="h4" color="initial">
                    OPD App
                  </Typography>
                </Box>
              </Box>
              <div
                style={{
                  height: '50px',
                }}
              >
                {loading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <button
                    type="button"
                    className="login-with-google-btn"
                    onClick={openGoogleLogin}
                  >
                    Sign in with Google
                  </button>
                )}
              </div>
            </Box>
          </Paper>
        </div>
      </Grid>
    </Grid>
  )
}

export default Login

/*


<div
      style={{
        display: "flex",
        width: "100%"
      }}
    >
      <div
        style={{
          height: "100vh",
          width: "100%",
          // background: "#FBDCC5",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div
          style={{
            width: "80%",
            height: "80%",
            background: "url('login.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain"
          }}
        />
      </div>
      
    </div>
*/
