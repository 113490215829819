import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  CardActions,
  IconButton,
  Pagination,
  Chip,
  Button,
  Tooltip,
  Divider,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import PageSearch from '../PageSearch/PageSearch'
import { useQuery } from 'react-query'
import {
  addOpd,
  getObjectDiff,
  getOpds,
  isMobile,
  updateOpd,
} from '../Services/Services'
import { useState, useEffect } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { primaryColor, secondaryColor } from '../constants'
import PageLoader from '../Common/PageLoader'
import CustomMenu from '../Common/CustomMenu'
import useNotify from '../useNotify'
import Container from '../Common/Container'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { OpdEditor } from '../OpdEditor/OpdEditor'

export default function OpdListPage() {
  const { notifyError, notifySuccess } = useNotify()

  const [sortBy, setSortBy] = useState('regNo')
  const [sortDirection, setSortDirection] = useState('DESC')

  const [pageNumber, setPageNumber] = useState(1)
  const [opdEditorConfig, setOpdEditorConfig] = useState({
    open: false,
  })
  const PAGE_SIZE = 18
  const [searchString, setSearchString] = useState('')

  const { isLoading, error, data, refetch } = useQuery(
    ['opds', pageNumber, searchString, sortBy, sortDirection],
    () =>
      getOpds(
        pageNumber,
        PAGE_SIZE,
        searchString,
        `${sortBy}|${sortDirection}`
      ),
    {
      keepPreviousData: true,
    }
  )

  useEffect(() => {
    if (error) {
      notifyError('Some Error Occured!')
    }
  }, [error, notifyError])

  if (isLoading) {
    return <PageLoader />
  }

  const getDirectionIcon = (label) => {
    const icon =
      sortBy === label ? (
        sortDirection === 'DESC' ? (
          <ArrowDownwardIcon />
        ) : (
          <ArrowUpwardIcon />
        )
      ) : (
        <></>
      )
    return icon
  }

  const toggleDirection = () => {
    setPageNumber(1)
    if (sortDirection === 'DESC') {
      setSortDirection('ASC')
    } else {
      setSortDirection('DESC')
    }
  }

  const getOptions = (opd) => {
    return [
      {
        action: () => {
          setOpdEditorConfig({
            open: true,
            edit: true,
            editItem: opd,
            onSubmit: async (_opd, newOpd) => {
              if (!newOpd) {
                setOpdEditorConfig((p) => {
                  return {
                    ...p,
                    reqInProgress: true,
                  }
                })
                try {
                  let diff = getObjectDiff(opd, _opd)
                  if (diff) {
                    await updateOpd(opd._id, diff)
                    notifySuccess('OPD Successfully Updated!')
                    setTimeout(refetch, 1500)
                  }
                  setOpdEditorConfig({ open: false })
                } catch (e) {
                  const message =
                    e?.response?.data?.message || 'Something went wrong'
                  notifyError(message)
                  setOpdEditorConfig((p) => {
                    return {
                      ...p,
                      reqInProgress: false,
                    }
                  })
                }
              }
            },
            onCancel: () => {
              setOpdEditorConfig({
                open: false,
              })
            },
          })
        },
        icon: <EditIcon />,
        text: 'Edit',
      },
    ]
  }

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          my: 2,
        }}
      >
        <Typography variant="h5" color="initial">
          OPDs
        </Typography>

        <Button
          onClick={() => {
            setOpdEditorConfig({
              open: true,
              edit: false,
              onSubmit: async (opd, newOpd) => {
                if (newOpd) {
                  setOpdEditorConfig((prev) => {
                    return {
                      ...prev,
                      reqInProgress: true,
                    }
                  })
                  try {
                    await addOpd(opd)
                    setOpdEditorConfig({ open: false })
                    notifySuccess('OPD Successfully Added')
                    setTimeout(refetch, 1500)
                  } catch (e) {
                    const message =
                      e?.response?.data?.message || 'Something went wrong'
                    notifyError(message)
                    setOpdEditorConfig((prev) => {
                      return {
                        ...prev,
                        reqInProgress: false,
                      }
                    })
                  }
                }
              },
              onCancel: () => {
                setOpdEditorConfig({
                  open: false,
                })
              },
            })
          }}
          variant="contained"
          startIcon={<AddIcon />}
          sx={{
            width: '150px',
            background: primaryColor,
            color: 'black',
            '&:hover': {
              background: primaryColor,
            },
          }}
        >
          OPD
        </Button>
      </Box>
      <Box sx={{ display: 'flex', marginBottom: '20px', alignItems: 'center' }}>
        <Typography
          variant="body2"
          component={'div'}
          sx={{ fontWeight: 'bold', fontSize: '16px' }}
        >
          Sort By
        </Typography>
        <Chip
          label="Default"
          sx={{ margin: '0 10px' }}
          variant={sortBy === 'createdAt' ? 'filled' : 'outlined'}
          onClick={() => {
            setPageNumber(1)
            setSortBy('createdAt')
            setSortDirection('DESC')
          }}
          deleteIcon={getDirectionIcon('createdAt')}
          onDelete={toggleDirection}
        />
        <Chip
          label="Name"
          sx={{ marginRight: '10px' }}
          variant={sortBy === 'name' ? 'filled' : 'outlined'}
          onClick={() => {
            setPageNumber(1)
            setSortBy('name')
            setSortDirection('DESC')
          }}
          deleteIcon={getDirectionIcon('name')}
          onDelete={toggleDirection}
        />
        <Chip
          label="Registration No"
          variant={sortBy === 'regNo' ? 'filled' : 'outlined'}
          onClick={() => {
            setPageNumber(1)
            setSortBy('regNo')
            setSortDirection('DESC')
          }}
          deleteIcon={getDirectionIcon('regNo')}
          onDelete={toggleDirection}
        />
      </Box>
      <Divider sx={{ my: 3 }} />
      <Card>
        <CardContent sx={{ minHeight: '500px' }}>
          <Box sx={{ my: 1, position: 'relative' }}>
            <PageSearch
              label={'Search Name, Phone No, Reg No'}
              onSearch={setSearchString}
            />
          </Box>
          {data?.data?.length > 0 && (
            <Grid container spacing={isMobile() ? 2 : 4}>
              {data?.data?.map((user) => (
                <Grid item sm={12} md={6} lg={4} width={'100%'} key={user._id}>
                  <Card
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <CardContent>
                      <Typography
                        component="div"
                        variant="h5"
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {user.name.toLowerCase()}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                      >
                        {user.phoneNo || 'Not Available'}
                      </Typography>
                    </CardContent>
                    <Box
                      sx={{
                        width: '100px',
                        background: secondaryColor,
                        display: 'flex',
                        flexBasis: '100px',
                        flexGrow: 0,
                        flexShrink: 0,
                        position: 'relative',
                      }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'relative',
                        }}
                      >
                        <Typography variant="h4" color="initial">
                          {user.regNo}
                        </Typography>
                        <Box
                          sx={{
                            position: 'absolute',
                            bottom: 0,
                          }}
                        >
                          <CustomMenu
                            small={true}
                            horizontal={true}
                            options={getOptions(user)}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
          {data?.data?.length === 0 && (
            <Box
              sx={{
                height: '300px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="h3" color="#eee">
                Create New OPD!
              </Typography>
            </Box>
          )}
        </CardContent>
        <CardActions>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                my: 2,
                display: 'flex',
                justifyContent: isMobile() ? 'center' : 'flex-end',
              }}
            >
              {data?.totalCount > 0 && (
                <Pagination
                  count={Math.ceil(data.totalCount / PAGE_SIZE)}
                  onChange={(a,b)=>setPageNumber(b)}
                />
              )}
            </Box>
          </Box>
        </CardActions>
      </Card>
      {opdEditorConfig.open && <OpdEditor {...opdEditorConfig} />}
    </Container>
  )
}
