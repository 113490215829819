import axios from 'axios'
import moment from 'moment/moment'
import mobile from 'is-mobile'
import { serverUrl } from '../constants'

function getToken() {
  let localToken = localStorage.getItem('token')
  return localToken ? `Bearer ${localToken}` : null
}

export function addOpd(opdDetails) {
  return axios({
    method: 'post',
    url: `${serverUrl}/opds`,
    data: opdDetails,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function updateOpd(id, changes) {
  return axios({
    method: 'put',
    url: `${serverUrl}/opds/${id}`,
    data: changes,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function getOpds(page = 1, pageSize = 10, searchString, sortBy) {
  return axios({
    method: 'get',
    url: `${serverUrl}/opds?page=${page}&pageSize=${pageSize}${
      searchString ? `&search=${searchString}` : ''
    }${sortBy ? `&sortBy=${sortBy}` : ''}`,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function getOpdDetails(id) {
  return axios({
    method: 'get',
    url: `${serverUrl}/opds/${id}`,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function removeBlog(id) {
  return axios({
    method: 'delete',
    url: `${serverUrl}/blogs/${id}/publish`,
    headers: {
      Authorization: getToken(),
    },
  }).then(function (response) {
    return response.data
  })
}

export function isMobile() {
  return mobile()
}

export function equalDate(d1, d2) {
  return new Date(d1).getTime() === new Date(d2).getTime()
}

export function isDate(str) {
  return moment(str, moment.ISO_8601, true).isValid()
}

export function getObjectDiff(old, latest) {
  const result = {}
  for (let key in latest) {
    if (old[key] !== undefined) {
      if (isDate(old[key])) {
        if (!equalDate(latest[key], old[key])) {
          result[key] = latest[key]
        }
      } else if (latest[key] !== old[key]) {
        result[key] = latest[key]
      }
    } else {
      result[key] = latest[key]
    }
  }
  return Object.keys(result).length ? result : null
}

export function getArrayDiff(old, latest, key) {
  let added = []
  let removed = []
  let updated = []
  let oldKeys = old.reduce((acc, i) => {
    acc[i[key]] = i
    return acc
  }, {})

  let latestKeys = latest.reduce((acc, i) => {
    acc[i[key]] = i
    return acc
  }, {})

  old.forEach((i) => {
    if (latestKeys[i[key]]) {
      let diff = getObjectDiff(i, latestKeys[i[key]])
      if (diff) {
        updated.push(latestKeys[i[key]])
      }
    } else {
      removed.push(i)
    }
  })

  latest.forEach((i) => {
    if (!oldKeys[i[key]]) {
      added.push(i)
    }
  })

  return { added, removed, updated }
}
