import { NotifyContext } from './NotificationContext'
import { useEffect, useContext, useRef, useState, useCallback } from 'react'

export default function useNotify() {
  const notificationManager = useContext(NotifyContext)

  const { notify, notifySuccess, notifyError } = notificationManager

  return {
    notify,
    notifySuccess,
    notifyError,
  }
}
